import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Bot, Menu, X, Home, Calculator, LineChart, PiggyBank, BookOpen, LogIn, Shield } from 'lucide-react';
import huantivaLogo from '../assets/huantiva-logo.png';
import { cn } from '@/lib/utils';
import { motion, AnimatePresence } from 'framer-motion';
import { AccessModal } from './banks/access/AccessModal';
import { useAuth } from '@/context/AuthContext';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCalculatorsOpen, setIsCalculatorsOpen] = useState(false);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const menuRef = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { isVerified, endSession } = useAuth();

  const menuItems = [
    {
      path: '/',
      label: 'Inicio',
      icon: Home,
      color: 'from-emerald-500 to-green-600',
      glow: 'group-hover:shadow-emerald-500/50'
    },
    {
      label: 'Calculadoras',
      icon: Calculator,
      color: 'from-blue-500 to-cyan-600',
      glow: 'group-hover:shadow-blue-500/50',
      subItems: [
        {
          path: '/calculadora-hipoteca',
          label: 'Calculadora de Hipoteca',
          icon: LineChart,
          color: 'from-blue-400 to-cyan-500',
          glow: 'group-hover:shadow-blue-400/50'
        },
        {
          path: '/cuanto-puedo-pedir',
          label: 'Calculadora de Máximo',
          icon: PiggyBank,
          color: 'from-blue-400 to-cyan-500',
          glow: 'group-hover:shadow-blue-400/50'
        }
      ]
    },
    {
      path: '/comparador-hipotecas',
      label: 'Comparador',
      icon: LineChart,
      color: 'from-purple-500 to-pink-600',
      glow: 'group-hover:shadow-purple-500/50'
    },
    {
      path: '/analisis',
      label: 'Análisis',
      icon: Bot,
      color: 'from-indigo-500 to-violet-600',
      glow: 'group-hover:shadow-indigo-500/50'
    },
    {
      path: '/articulos',
      label: 'Artículos',
      icon: BookOpen,
      color: 'from-rose-500 to-red-600',
      glow: 'group-hover:shadow-rose-500/50'
    },
  ];

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (menuRef.current) {
        const rect = menuRef.current.getBoundingClientRect();
        setMousePosition({
          x: e.clientX - rect.left,
          y: e.clientY - rect.top,
        });
      }
    };

    if (isMenuOpen) {
      window.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isMenuOpen]);

  const handleAccessComplete = () => {
    setShowAccessModal(false);
  };

  return (
    <header className="fixed w-full top-0 z-50">
      <div className="absolute inset-0 bg-gray-900/80 backdrop-blur-xl border-b border-white/10" />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <Link to="/" className="flex items-center space-x-3">
            <img
              src={huantivaLogo}
              alt="Huantiva Logo"
              className="h-10 w-10"
            />
            <span className="text-2xl font-bold bg-gradient-to-r from-emerald-400 to-purple-400 bg-clip-text text-transparent">
              Huantiva
            </span>
          </Link>

          <div className="flex items-center gap-4">

            {isVerified ? (
              <button
                onClick={endSession}
                className={cn(
                  "px-4 py-2 rounded-lg",
                  "bg-gradient-to-r from-red-500/80 to-pink-500/80",
                  "text-white",
                  "border border-white/10",
                  "hover:from-red-600 hover:to-pink-600",
                  "transition-all duration-300",
                  "flex items-center gap-2"
                )}
              >
                <LogIn className="w-4 h-4" />
                <span className="hidden sm:inline">Cerrar sesión</span>
              </button>
            ) : (
              <button
                onClick={() => setShowAccessModal(true)}
                className={cn(
                  "px-4 py-2 rounded-lg",
                  "bg-gradient-to-r from-blue-500/80 to-purple-500/80",
                  "text-white",
                  "border border-white/10",
                  "hover:from-blue-600 hover:to-purple-600",
                  "transition-all duration-300",
                  "flex items-center gap-2"
                )}
              >
                <LogIn className="w-4 h-4" />
                <span className="hidden sm:inline">Iniciar sesión</span>
              </button>
            )}

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className={cn(
                "relative p-2 rounded-xl z-50",
                "bg-gradient-to-r from-blue-500/80 to-purple-500/80",
                "hover:from-blue-600 hover:to-purple-600",
                "border border-white/10",
                "transition-colors duration-300",
                "shadow-lg shadow-purple-500/20"
              )}
            >
              <AnimatePresence mode="wait">
                {isMenuOpen ? (
                  <motion.div
                    key="close"
                    initial={{ rotate: -90, opacity: 0 }}
                    animate={{ rotate: 0, opacity: 1 }}
                    exit={{ rotate: 90, opacity: 0 }}
                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                  >
                    <X className="w-6 h-6 text-white" />
                  </motion.div>
                ) : (
                  <motion.div
                    key="menu"
                    initial={{ rotate: 90, opacity: 0 }}
                    animate={{ rotate: 0, opacity: 1 }}
                    exit={{ rotate: -90, opacity: 0 }}
                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                  >
                    <Menu className="w-6 h-6 text-white" />
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.button>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            ref={menuRef}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{
              type: "spring",
              stiffness: 400,
              damping: 40
            }}
            className="relative"
          >
            <nav className="relative grid gap-2 p-4">
              {menuItems.map((item, index) => {
                const { path, label, icon: Icon, color, glow, subItems } = item;

                return (
                  <motion.div
                    key={path || label}
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -50, opacity: 0 }}
                    transition={{
                      type: "spring",
                      stiffness: 400,
                      damping: 40,
                      delay: index * 0.1
                    }}
                  >
                    {subItems ? (
                      <div
                        className="space-y-2"
                        onMouseEnter={() => {
                          if (window.innerWidth > 768) {
                            setIsCalculatorsOpen(true);
                          }
                        }}
                        onMouseLeave={() => {
                          if (window.innerWidth > 768) {
                            setIsCalculatorsOpen(false);
                          }
                        }}
                        onClick={() => {
                          if (window.innerWidth <= 768) {
                            setIsCalculatorsOpen(prev => !prev);
                          }
                        }}
                      >
                        <div
                          className={cn(
                            "group relative flex items-center gap-3 p-4 rounded-xl",
                            "transition-all duration-300",
                            "bg-gradient-to-r from-gray-800/50 to-gray-900/50",
                            "border border-white/5 hover:border-white/10",
                            "backdrop-blur-lg",
                            "cursor-pointer"
                          )}
                        >
                          <motion.div
                            className={cn(
                              "absolute inset-0 rounded-xl opacity-0 group-hover:opacity-100",
                              "transition-opacity duration-500",
                              "bg-gradient-to-r",
                              color
                            )}
                            style={{ opacity: 0.1 }}
                          />

                          <div className={cn(
                            "p-2 rounded-lg",
                            "bg-gradient-to-r",
                            color,
                            "shadow-lg transition-shadow duration-300",
                            glow
                          )}>
                            <Icon className="w-5 h-5 text-white" />
                          </div>

                          <span className="text-white font-medium">{label}</span>
                        </div>

                        <div className={cn(
                          "pl-8 space-y-2",
                          "transition-all duration-300",
                          isCalculatorsOpen ? "opacity-100 max-h-96" : "opacity-0 max-h-0 overflow-hidden"
                        )}>
                          {subItems.map((subItem) => (
                            <Link
                              key={subItem.path}
                              to={subItem.path}
                              onClick={() => setIsMenuOpen(false)}
                              className={cn(
                                "group relative flex items-center gap-3 p-3 rounded-xl",
                                "transition-all duration-300 hover:scale-[1.02]",
                                "bg-gradient-to-r from-gray-800/50 to-gray-900/50",
                                "border border-white/5 hover:border-white/10",
                                "backdrop-blur-lg"
                              )}
                            >
                              <motion.div
                                className={cn(
                                  "absolute inset-0 rounded-xl opacity-0 group-hover:opacity-100",
                                  "transition-opacity duration-500",
                                  "bg-gradient-to-r",
                                  subItem.color
                                )}
                                style={{ opacity: 0.1 }}
                              />

                              <div className={cn(
                                "p-2 rounded-lg",
                                "bg-gradient-to-r",
                                subItem.color,
                                "shadow-lg transition-shadow duration-300",
                                subItem.glow
                              )}>
                                <subItem.icon className="w-5 h-5 text-white" />
                              </div>

                              <span className="text-white font-medium">{subItem.label}</span>
                            </Link>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <Link
                        to={path}
                        onClick={() => setIsMenuOpen(false)}
                        className={cn(
                          "group relative flex items-center gap-3 p-4 rounded-xl",
                          "transition-all duration-300",
                          "hover:scale-[1.02]",
                          "bg-gradient-to-r from-gray-800/50 to-gray-900/50",
                          "border border-white/5 hover:border-white/10",
                          "backdrop-blur-lg"
                        )}
                      >
                        <motion.div
                          className={cn(
                            "absolute inset-0 rounded-xl opacity-0 group-hover:opacity-100",
                            "transition-opacity duration-500",
                            "bg-gradient-to-r",
                            color
                          )}
                          style={{ opacity: 0.1 }}
                        />

                        <div className={cn(
                          "p-2 rounded-lg",
                          "bg-gradient-to-r",
                          color,
                          "shadow-lg transition-shadow duration-300",
                          glow
                        )}>
                          <Icon className="w-5 h-5 text-white" />
                        </div>

                        <span className="text-white font-medium">{label}</span>
                      </Link>
                    )}
                  </motion.div>
                );
              })}
            </nav>
          </motion.div>
        )}
      </AnimatePresence>

      <AccessModal
        isOpen={showAccessModal}
        onClose={() => setShowAccessModal(false)}
        onUnlock={handleAccessComplete}
      />
    </header>
  );
}
