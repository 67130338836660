import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';
import { cn } from '@/lib/utils';

export function HomeFooter() {
  const socialLinks = [
    { icon: Facebook, href: 'https://facebook.com/huantiva', label: 'Facebook' },
    { icon: Twitter, href: 'https://twitter.com/huantiva', label: 'Twitter' },
    { icon: Instagram, href: 'https://instagram.com/huantiva', label: 'Instagram' },
    { icon: Linkedin, href: 'https://linkedin.com/company/huantiva', label: 'LinkedIn' }
  ];

  const footerLinks = [
    {
      title: 'Empresa',
      links: [
        { label: 'Sobre nosotros', href: '/sobre-nosotros' },
        { label: 'Contacto', href: '/contacto' },
        { label: 'Blog', href: '/articulos' }
      ]
    },
    {
      title: 'Legal',
      links: [
        { label: 'Aviso legal', href: '/aviso-legal' },
        { label: 'Política de privacidad', href: '/privacidad' },
        { label: 'Política de cookies', href: '/politica-cookies' }
      ]
    },
    {
      title: 'Servicios',
      links: [
        { label: 'Broker', href: '/broker' },
        { label: 'Comparador', href: '/comparador-hipotecas' },
        { label: 'Análisis', href: '/analisis' }
      ]
    }
  ];

  return (
    <footer className={cn(
      "relative mt-auto",
      "bg-black/50 backdrop-blur-sm border-t border-white/10",
      "py-8 sm:py-12"
    )}>
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Logo and description */}
          <div className="space-y-4">
            <Link to="/" className="text-2xl font-bold bg-gradient-to-r from-emerald-400 to-purple-400 bg-clip-text text-transparent">
              Huantiva
            </Link>
            <p className="text-sm text-gray-400">
              Tu asistente inteligente para encontrar la hipoteca perfecta. Compara, calcula y toma la mejor decisión.
            </p>
          </div>

          {/* Links sections */}
          {footerLinks.map((section) => (
            <div key={section.title}>
              <h3 className="text-white font-semibold mb-4">{section.title}</h3>
              <ul className="space-y-2">
                {section.links.map((link) => (
                  <li key={link.href}>
                    <Link
                      to={link.href}
                      className="text-sm text-gray-400 hover:text-white transition-colors"
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Social and copyright */}
        <div className="mt-8 pt-8 border-t border-white/10 flex flex-col sm:flex-row items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            {socialLinks.map((social) => (
              <a
                key={social.label}
                href={social.href}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors"
                aria-label={social.label}
              >
                <social.icon className="w-5 h-5" />
              </a>
            ))}
          </div>

          <p className="text-sm text-gray-400">
            © {new Date().getFullYear()} Huantiva. Todos los derechos reservados.
          </p>
        </div>
      </div>
    </footer>
  );
}
