import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ChatbotProvider } from './components/chatbot/ChatbotProvider';
import { AuthProvider } from './context/AuthContext';
import { AnalysisProvider } from './context/AnalysisContext';
import { router } from './routes';
import { register as registerServiceWorker } from './serviceWorkerRegistration';
import './index.css';

// Error boundary wrapper
import { ErrorBoundary } from '@/components/shared/ErrorBoundary';

// Only register service worker in production
if (process.env.NODE_ENV === 'production') {
  registerServiceWorker();
}

const root = document.getElementById('root');
if (!root) throw new Error('Root element not found');

createRoot(root).render(
  <StrictMode>
    <ErrorBoundary>
      <HelmetProvider>
        <AuthProvider>
          <AnalysisProvider>
            <ChatbotProvider>
              <RouterProvider router={router} />
            </ChatbotProvider>
          </AnalysisProvider>
        </AuthProvider>
      </HelmetProvider>
    </ErrorBoundary>
  </StrictMode>
);