import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { LogOut, Settings, FileText, LayoutGrid } from 'lucide-react';
import { useAdminAuth } from '@/hooks/useAdminAuth';
import { ROUTES } from '@/routes/paths';
import { cn } from '@/lib/utils';

export default function AdminLayout() {
  const navigate = useNavigate();
  const { admin, logout } = useAdminAuth();

  const handleLogout = async () => {
    await logout();
    navigate(ROUTES.ADMIN.LOGIN);
  };

  const menuItems = [
    { icon: LayoutGrid, label: 'Dashboard', path: ROUTES.ADMIN.DASHBOARD },
    { icon: FileText, label: 'Artículos', path: ROUTES.ADMIN.ARTICLES },
    { icon: Settings, label: 'Configuración', path: ROUTES.ADMIN.SETTINGS }
  ];

  return (
    <div className="min-h-screen bg-gray-900 flex">
      {/* Sidebar */}
      <aside className="w-64 bg-gray-800 border-r border-gray-700">
        <div className="h-full flex flex-col">
          <div className="p-4 border-b border-gray-700">
            <h2 className="text-xl font-bold text-white">
              Panel Admin
            </h2>
            {admin?.nombre && (
              <p className="text-sm text-gray-400 mt-1">
                {admin.nombre}
              </p>
            )}
          </div>

          <nav className="flex-1 p-4">
            <ul className="space-y-2">
              {menuItems.map((item) => (
                <li key={item.path}>
                  <button
                    onClick={() => navigate(item.path)}
                    className={cn(
                      "w-full flex items-center gap-3 px-4 py-2",
                      "text-gray-300 hover:text-white",
                      "hover:bg-gray-700/50 rounded-lg",
                      "transition-colors"
                    )}
                  >
                    <item.icon className="w-5 h-5" />
                    <span>{item.label}</span>
                  </button>
                </li>
              ))}
            </ul>
          </nav>

          <div className="p-4 border-t border-gray-700">
            <button
              onClick={handleLogout}
              className={cn(
                "w-full flex items-center gap-3 px-4 py-2",
                "text-red-400 hover:text-red-300",
                "hover:bg-red-500/10 rounded-lg",
                "transition-colors"
              )}
            >
              <LogOut className="w-5 h-5" />
              <span>Cerrar sesión</span>
            </button>
          </div>
        </div>
      </aside>

      {/* Main content */}
      <main className="flex-1 overflow-auto">
        <Outlet />
      </main>
    </div>
  );
}