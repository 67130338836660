export const ROUTES = {
  HOME: '/',
  MORTGAGE_CALCULATOR: '/calculadora-hipoteca',
  MORTGAGE_COMPARATOR: '/comparador-hipotecas',
  MAXIMUM_CALCULATOR: '/cuanto-puedo-pedir',
  COMBINED_TOOLS: '/herramientas-hipotecarias',
  ANALYSIS: '/analisis',
  ARTICLES: '/articulos',
  BROKER: '/broker',
  ADMIN: {
    LOGIN: '/admin/login',
    DASHBOARD: '/admin/dashboard',
    ARTICLES: '/admin/articles',
    ARTICLE_NEW: '/admin/articles/new',
    ARTICLE_EDIT: (id: string) => `/admin/articles/edit/${id}`,
    CATEGORIES: '/admin/categories',
    SETTINGS: '/admin/settings'
  }
} as const;