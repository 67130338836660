import React, { Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Layout from '@/components/layout/Layout';
import AdminLayout from '@/components/admin/AdminLayout';
import LoadingSpinner from '@/components/shared/LoadingSpinner';

// Lazy load route components
const Home = React.lazy(() => import('@/pages/Home'));
const MortgageCalculator = React.lazy(() => import('@/pages/MortgageCalculator'));
const MortgageComparator = React.lazy(() => import('@/pages/MortgageComparator'));
const MaximumCalculator = React.lazy(() => import('@/pages/MaximumCalculator'));
const CombinedTools = React.lazy(() => import('@/pages/CombinedTools'));
const Analysis = React.lazy(() => import('@/pages/Analysis'));
const ArticlePage = React.lazy(() => import('@/pages/ArticlePage'));
const ArticlesPage = React.lazy(() => import('@/pages/ArticlesPage'));
const BrokerPage = React.lazy(() => import('@/pages/BrokerPage'));
const PoliticaCookies = React.lazy(() => import('@/pages/PoliticaCookies'));
const Privacidad = React.lazy(() => import('@/pages/Privacidad'));
const AvisoLegal = React.lazy(() => import('@/pages/AvisoLegal'));
const Contacto = React.lazy(() => import('@/pages/Contacto'));
const SobreNosotros = React.lazy(() => import('@/pages/SobreNosotros'));

// Admin pages
const AdminLogin = React.lazy(() => import('@/pages/admin/login'));
const AdminDashboard = React.lazy(() => import('@/pages/admin/dashboard'));
const AdminArticles = React.lazy(() => import('@/pages/admin/articles'));
const AdminArticleForm = React.lazy(() => import('@/pages/admin/articles/form'));
const AdminCategories = React.lazy(() => import('@/pages/admin/categories'));
const AdminSettings = React.lazy(() => import('@/pages/admin/settings'));

// Suspense wrapper component
const SuspenseWrapper = ({ children }: { children: React.ReactNode }) => (
  <Suspense fallback={<LoadingSpinner />}>
    {children}
  </Suspense>
);

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <SuspenseWrapper><Home /></SuspenseWrapper>
      },
      {
        path: 'calculadora-hipoteca',
        element: <SuspenseWrapper><MortgageCalculator /></SuspenseWrapper>
      },
      {
        path: 'comparador-hipotecas',
        element: <SuspenseWrapper><MortgageComparator /></SuspenseWrapper>
      },
      {
        path: 'cuanto-puedo-pedir',
        element: <SuspenseWrapper><MaximumCalculator /></SuspenseWrapper>
      },
      {
        path: 'herramientas-hipotecarias',
        element: <SuspenseWrapper><CombinedTools /></SuspenseWrapper>
      },
      {
        path: 'analisis',
        element: <SuspenseWrapper><Analysis /></SuspenseWrapper>
      },
      {
        path: 'articulos',
        element: <SuspenseWrapper><ArticlesPage /></SuspenseWrapper>
      },
      {
        path: 'articulos/:slug',
        element: <SuspenseWrapper><ArticlePage /></SuspenseWrapper>
      },
      {
        path: 'broker',
        element: <SuspenseWrapper><BrokerPage /></SuspenseWrapper>
      },
      {
        path: 'politica-cookies',
        element: <SuspenseWrapper><PoliticaCookies /></SuspenseWrapper>
      },
      {
        path: 'privacidad',
        element: <SuspenseWrapper><Privacidad /></SuspenseWrapper>
      },
      {
        path: 'aviso-legal',
        element: <SuspenseWrapper><AvisoLegal /></SuspenseWrapper>
      },
      {
        path: 'contacto',
        element: <SuspenseWrapper><Contacto /></SuspenseWrapper>
      },
      {
        path: 'sobre-nosotros',
        element: <SuspenseWrapper><SobreNosotros /></SuspenseWrapper>
      }
    ]
  },
  {
    path: '/admin',
    children: [
      {
        path: 'login',
        element: <SuspenseWrapper><AdminLogin /></SuspenseWrapper>
      },
      {
        element: <AdminLayout />,
        children: [
          {
            path: 'dashboard',
            element: <SuspenseWrapper><AdminDashboard /></SuspenseWrapper>
          },
          {
            path: 'articles',
            element: <SuspenseWrapper><AdminArticles /></SuspenseWrapper>
          },
          {
            path: 'articles/new',
            element: <SuspenseWrapper><AdminArticleForm /></SuspenseWrapper>
          },
          {
            path: 'articles/edit/:id',
            element: <SuspenseWrapper><AdminArticleForm /></SuspenseWrapper>
          },
          {
            path: 'categories',
            element: <SuspenseWrapper><AdminCategories /></SuspenseWrapper>
          },
          {
            path: 'settings',
            element: <SuspenseWrapper><AdminSettings /></SuspenseWrapper>
          }
        ]
      }
    ]
  }
]);
