import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import Header from '@/components/Header';
import { BackButton } from '@/components/shared/BackButton';
import { CookieConsent } from '@/components/CookieConsent';
import { HomeFooter } from '@/components/home/HomeFooter';
import { usePageTracking } from '@/hooks/usePageTracking';

export default function Layout() {
  const location = useLocation();
  const isHome = location.pathname === '/';

  // Initialize page tracking
  usePageTracking();

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      <Header />
      {!isHome && <BackButton />}
      <main className={cn(
        "flex-1",
        isHome ? "" : "pt-16"
      )}>
        <Outlet />
      </main>
      {isHome && <HomeFooter />}
      <CookieConsent />
    </div>
  );
}
