import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';

interface AdminSession {
  email: string;
  expiresAt: string;
}

interface Admin {
  id: string;
  email: string;
  nombre: string;
  ultimo_acceso: string;
}

export function useAdminAuth() {
  const [admin, setAdmin] = useState<Admin | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkSession();
  }, []);

  const checkSession = async () => {
    try {
      const storedSession = localStorage.getItem('admin_session');
      if (!storedSession) {
        setLoading(false);
        return;
      }

      const session = JSON.parse(storedSession) as AdminSession;
      if (new Date(session.expiresAt) < new Date()) {
        localStorage.removeItem('admin_session');
        setAdmin(null);
        setLoading(false);
        return;
      }

      const { data, error } = await supabase
        .from('administradores')
        .select('*')
        .eq('email', session.email)
        .eq('activo', true)
        .single();

      if (error || !data) {
        localStorage.removeItem('admin_session');
        setAdmin(null);
      } else {
        setAdmin(data);
      }
    } catch (error) {
      console.error('Error checking session:', error);
    } finally {
      setLoading(false);
    }
  };

  const login = async (email: string, codigo: string) => {
    try {
      const { data, error } = await supabase
        .from('administradores')
        .select('*')
        .eq('email', email)
        .eq('codigo', codigo)
        .eq('activo', true)
        .single();

      if (error || !data) {
        throw new Error('Credenciales inválidas');
      }

      // Update último_acceso
      await supabase
        .from('administradores')
        .update({ ultimo_acceso: new Date().toISOString() })
        .eq('id', data.id);

      // Set session
      const expiresAt = new Date();
      expiresAt.setHours(expiresAt.getHours() + 24); // 24-hour session

      localStorage.setItem('admin_session', JSON.stringify({
        email: data.email,
        expiresAt: expiresAt.toISOString()
      }));

      setAdmin(data);
      return true;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const logout = async () => {
    localStorage.removeItem('admin_session');
    setAdmin(null);
  };

  return {
    admin,
    loading,
    login,
    logout,
    isAuthenticated: !!admin
  };
}