import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';

export function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasAccepted = localStorage.getItem('cookiesAccepted');
    if (!hasAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className={cn(
      "fixed bottom-0 left-0 right-0 z-50",
      "bg-gray-900/95 backdrop-blur-sm border-t border-gray-800",
      "p-4 sm:p-6",
      "animate-fade-in"
    )}>
      <div className="container mx-auto max-w-4xl flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
        <div className="flex-1">
          <p className="text-gray-300 text-sm">
            Utilizamos cookies propias y de terceros para mejorar nuestros servicios. 
            Al continuar navegando, aceptas el uso de cookies y nuestras{' '}
            <Link to="/privacidad" className="text-blue-400 hover:text-blue-300">
              Política de Privacidad
            </Link>{' '}
            y{' '}
            <Link to="/politica-cookies" className="text-blue-400 hover:text-blue-300">
              Política de Cookies
            </Link>.
          </p>
        </div>
        <div className="flex items-center gap-4">
          <button
            onClick={handleAccept}
            className={cn(
              "px-4 py-2 bg-blue-500 text-white rounded-lg",
              "hover:bg-blue-600 transition-colors",
              "text-sm font-medium"
            )}
          >
            Aceptar
          </button>
          <button
            onClick={() => setIsVisible(false)}
            className="text-gray-400 hover:text-white transition-colors"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
}
